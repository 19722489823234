import * as React from 'react';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useEffect, useState, forwardRef } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { ThreeCircles } from 'react-loader-spinner';
import Draggable from 'react-draggable';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper
} from '@mui/material';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { LoadingButton } from '@mui/lab';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import {
  MiscInvListHead,
  MiscInvListToolbar,
  MiscInvMoreMenu
} from '../../components/miscellaneos-invoicing/table';
import { AddEditForm } from '../../components/miscellaneos-invoicing/add-edit';
//
import MiscellaneosInvoicingService from '../../services/billing-and-invoicing/MiscellaneosInvoicing.service';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'hawb', label: 'HAWB', alignRight: false },
  { id: 'invoiceDate', label: 'Invoice Date', alignRight: false },
  { id: 'invoiceNo', label: 'Invoice No.', alignRight: false },
  { id: 'totalAmt', label: 'Total Amount', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

const MuiAlert = forwardRef(function MuiAlert(props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_miscInv) => _miscInv.invoiceNo.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function DelPaperComponent(props) {
  return (
    <Draggable handle="#del-draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

function EditPaperComponent(props) {
  return (
    <Draggable handle="#edit-draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

function AddPaperComponent(props) {
  return (
    <Draggable handle="#add-draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MiscellaneosInvoicing() {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('hawb');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [miscInvHeader, setMiscInvHeader] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [openDelConfirm, setOpenDelConfirm] = useState(false);
  const [toDelItem, setToDelItem] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [toEditItem, setToEditItem] = useState(null);
  const [editIsSubmitting, setEditIsSubmitting] = useState(false);
  const [openAddEditMscInv, setOpenAddEditMscInv] = useState(false);
  const [addIsSubmitting, setAddIsSubmitting] = useState(false);

  useEffect(() => {
    getMiscInvHeader();
  }, []);

  const getMiscInvHeader = async () => {
    setLoading(true);

    await MiscellaneosInvoicingService.getMiscInvHeader()
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          setMiscInvHeader(data.responseData);
        } else {
          setMiscInvHeader([]);
          showAlert('error', data.responseMessage);
        }
      })
      .catch((error) => {
        showAlert('error', error.message);
      });

    setLoading(false);
  };

  const saveMiscInv = async (dataHeader, dataDetails) => {
    await MiscellaneosInvoicingService.insertMiscInvHeader(dataHeader)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          const miscInvHeaderId = data.miscInvHeaderId;

          dataDetails.forEach(async (item, index) => {
            item.miscInvHeaderId = miscInvHeaderId;
            await MiscellaneosInvoicingService.insertMiscInvDetails(item)
              .then((response) => {
                return response.data;
              })
              .then((data) => {
                if (data.responseCode === 200) {
                  setMiscInvHeader(data.responseData);
                }
                // else {
                //   setMiscInvHeader([]);
                //   showAlert('error', data.responseMessage);
                // }
              });
            // .catch((error) => {
            //   showAlert('error', error.message);
            // });
          });
        } else {
          showAlert('error', data.responseMessage);
        }
      })
      .catch((error) => {
        showAlert('error', error.message);
      });
  };

  const showAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const hideAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  /** Delete **/
  const handleOpenDelConfirm = (item) => {
    setToDelItem(item);
    setOpenDelConfirm(true);
  };

  const handleCloseDelConfirm = () => {
    setToDelItem(null);
    setOpenDelConfirm(false);
  };

  const handleConfirmDel = async () => {
    setIsDeleting(true);
    setLoading(true);

    const data = {
      id: toDelItem.id
    };

    await MiscellaneosInvoicingService.deleteMiscInv(data)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          getMiscInvHeader();
          showAlert('success', data.responseMessage);
        } else {
          showAlert('error', data.responseMessage);
        }

        setIsDeleting(false);
        setOpenDelConfirm(false);
        setToDelItem(null);
      })
      .catch((error) => {
        setIsDeleting(false);
        setOpenDelConfirm(false);
        setToDelItem(null);
        showAlert('error', error.message);
      });

    setLoading(false);
  };

  /** Add/Edit **/
  const handleOpenAddEditMscInv = (item) => {
    setToEditItem(item);
    setOpenAddEditMscInv(true);
  };

  const handleCloseAddEditMscInv = () => {
    setToEditItem(null);
    setOpenAddEditMscInv(false);
  };

  const handleConfirmAddEdit = async (dataHeader, dataDetails, printInvoice) => {
    setAddIsSubmitting(true);
    setLoading(true);

    await MiscellaneosInvoicingService.insertMiscInvHeader(dataHeader)
      .then((response) => {
        return response.data;
      })
      .then(async (data) => {
        if (data.responseCode === 200) {
          const miscInvHeaderId = data.miscInvHeaderId;

          let miscInvDetailsCount = 0;
          await dataDetails.forEach(async (item, index) => {
            item.miscInvHeaderId = miscInvHeaderId;
            await MiscellaneosInvoicingService.insertMiscInvDetails(item)
              .then((response) => {
                return response.data;
              })
              .then((data) => {
                if (data.responseCode === 200) {
                  miscInvDetailsCount++;
                }
              });
          });

          setOpenAddEditMscInv(false);
          showAlert('success', 'New Invoice successfully created.');
          setAddIsSubmitting(false);
          getMiscInvHeader();
        } else {
          showAlert('error', data.responseMessage);
          setAddIsSubmitting(false);
          setLoading(false);
          return;
        }
      })
      .catch((error) => {
        showAlert('error', error.message);
        setLoading(false);
        return;
      });

    if (printInvoice) {
      handlePrintInvoice(dataHeader);
    }
  };

  /** Print Invoice **/
  const handlePrintInvoice = async (item) => {
    setLoading(true);

    const data = {
      invoiceNo: item.invoiceNo
    };

    let urlFinal = '';

    await MiscellaneosInvoicingService.searchMiscInvoiceData(data)
      .then((response) => {
        return response.data;
      })
      .then(async (data) => {
        if (data.responseCode === 200) {
          /** Invoice **/
          const byteCharacters = atob(data.responseData.pdfInvoice);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = await new Blob([byteArray], { type: 'application/pdf' });
          const url = await window.URL.createObjectURL(blob);
          urlFinal = url;

          if (urlFinal !== undefined && urlFinal !== '') {
            // window.open(urlFinal,'_blank', 'toolbar=0,location=0,menubar=0');
            window.open(urlFinal, '_blank');
          }

          showAlert('success', data.responseMessage);
        } else {
          showAlert('error', data.responseMessage);
        }
      })
      .catch((error) => {
        showAlert('error', error.message);
      });

    setLoading(false);
  };

  /** **/
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = miscInvHeader
        .filter((item) => item.user_access !== 1 && item.deleted_at === null)
        .map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - miscInvHeader.length) : 0;

  const filteredMiscInvHeader = applySortFilter(
    miscInvHeader,
    getComparator(order, orderBy),
    filterName
  );

  const isMiscInvHeaderNotFound = filteredMiscInvHeader.length === 0;

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name) => {
    if (name === undefined || name === null) return '';

    const _name = name.trim();
    const children =
      _name.split(' ').length > 1
        ? `${_name.split(' ')[0][0]}${_name.split(' ')[1][0]}`
        : `${_name.split(' ')[0][0]}`;

    return {
      sx: {
        bgcolor: stringToColor(name) //'#ed1c24'
      },
      children: children.toUpperCase()
    };
  };

  return (
    <>
      <ThreeCircles
        height="100"
        width="100"
        color="#ed1c24"
        wrapperStyle={{
          position: 'fixed',
          justifyContent: 'center',
          alignItems: 'center',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          zIndex: '99999',
          textAlign: 'center'
        }}
        wrapperClass=""
        visible={loading}
        ariaLabel="three-circles-rotating"
        outerCircleColor=""
        innerCircleColor=""
        middleCircleColor=""
      />
      <Page title="Miscellaneos Invoicing | RAF Billing">
        <Container>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              onClose={hideAlert}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MuiAlert onClose={hideAlert} severity={alertType} sx={{ width: '100%' }}>
                {alertMessage}
              </MuiAlert>
            </Snackbar>
          </Stack>
        </Container>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            {/* <Typography variant="h4" gutterBottom>
            User
          </Typography> */}
            <Box sx={{ flexGrow: 1 }} />
            <Button
              variant="contained"
              onClick={() => handleOpenAddEditMscInv(null)}
              startIcon={<Icon icon={plusFill} />}
            >
              New Invoice
            </Button>
          </Stack>

          <Card>
            <MiscInvListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <MiscInvListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={
                      miscInvHeader.filter(
                        (item) => item.user_access !== 1 && item.deleted_at === null
                      ).length
                    }
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredMiscInvHeader
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const { id, hawb, invoiceDate, invoiceNo, totalAmt } = row;
                        const isItemSelected = selected.indexOf(hawb) !== -1;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            {/* <TableCell padding="checkbox">
                            {user_access !== 1 && deleted_at === null ? (
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, name)}
                              />
                            ) : (
                              <Checkbox disabled />
                            )}
                          </TableCell> */}
                            <TableCell component="th" scope="row" padding="normal">
                              {hawb}
                            </TableCell>
                            <TableCell align="left">{invoiceDate}</TableCell>
                            <TableCell align="left">{invoiceNo}</TableCell>
                            <TableCell align="left">
                              {parseFloat(totalAmt)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </TableCell>
                            <TableCell align="right">
                              <MiscInvMoreMenu
                                item={row}
                                onDeleteClick={handleOpenDelConfirm}
                                onEditClick={handleOpenAddEditMscInv}
                                onPrintInvoiceClick={handlePrintInvoice}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={5} />
                      </TableRow>
                    )}
                  </TableBody>
                  {miscInvHeader.length > 0 && isMiscInvHeaderNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={5} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={miscInvHeader.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>

        {/** Delete Confirmation Dialog **/}
        <Dialog
          open={openDelConfirm}
          onClose={handleCloseDelConfirm}
          PaperComponent={DelPaperComponent}
          aria-labelledby="del-draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: 'move', color: '#ed1c24' }} id="del-draggable-dialog-title">
            Delete Misc. Invoice Confirmation
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Confirming this action will delete this miscellaneos invoice.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseDelConfirm}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              loading={isDeleting}
              onClick={handleConfirmDel}
              autoFocus
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>

        {/** Add/Edit Dialog **/}
        <Dialog
          fullWidth
          maxWidth="lg"
          open={openAddEditMscInv}
          onClose={handleCloseAddEditMscInv}
          PaperComponent={AddPaperComponent}
          aria-labelledby="add-draggable-dialog-title"
          TransitionComponent={Transition}
        >
          <DialogTitle style={{ cursor: 'move' }} id="edit-draggable-dialog-title">
            <Stack direction="row">
              {toEditItem ? 'Edit Misc. Invoice' : 'Add New Misc. Invoice'}
              <Box sx={{ flexGrow: 1 }} />
              <Button autoFocus onClick={handleCloseAddEditMscInv}>
                Cancel
              </Button>
            </Stack>
          </DialogTitle>
          <DialogContent dividers={true}>
            <AddEditForm
              editData={toEditItem}
              isSubmitting={addIsSubmitting}
              onSubmit={handleConfirmAddEdit}
            />
          </DialogContent>
        </Dialog>
      </Page>
    </>
  );
}
