module.exports = {
  development: {
    siteUrl: 'http://localhost:5001',
    api: {
      baseUrl: 'http://localhost:5002'
    }
  },
  production: {
    siteUrl: 'https://billing.raf.ph',
    api: {
      baseUrl: 'https://billingapi.raf.ph/v1'
    }
  }
};
