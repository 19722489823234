import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import printerFill from '@iconify/icons-eva/printer-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import PropTypes from 'prop-types';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

// ----------------------------------------------------------------------

MiscInvMoreMenu.propTypes = {
  item: PropTypes.object,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onPrintInvoiceClick: PropTypes.func,
};

export default function MiscInvMoreMenu({
  item,
  onDeleteClick,
  onEditClick,
  onPrintInvoiceClick,
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleDeleteCLick = (item) => {
    setIsOpen(false);
    onDeleteClick(item);
  };

  const handleEditClick = (item) => {
    setIsOpen(false);
    onEditClick(item);
  };

  const handlePrintInvoiceClick = (item) => {
    setIsOpen(false);
    onPrintInvoiceClick(item);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {item.user_access !==  1 && (
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => handleDeleteCLick(item)}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        )}

        {/* <MenuItem sx={{ color: 'text.secondary' }} onClick={() => handleEditClick(item)}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}

        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => handlePrintInvoiceClick(item)}>
          <ListItemIcon>
            <Icon icon={printerFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Print Invoice" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
