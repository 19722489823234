import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import { ThreeCircles } from 'react-loader-spinner';
import Draggable from 'react-draggable';
import moment from 'moment';
// material
import {
  Stack,
  TextField,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Divider,
  Chip,
  Checkbox,
  FormControlLabel,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DatePicker from '@mui/lab/DatePicker';
import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
//
import MiscellaneosInvoicingService from '../../../services/billing-and-invoicing/MiscellaneosInvoicing.service';
import MiscInvCharges from '../misc-inv-charges';

// ----------------------------------------------------------------------

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

AddEditForm.propTypes = {
  editData: PropTypes.object,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default function AddEditForm({ editData, isSubmitting, onSubmit }) {
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const [hawb, setHAWB] = useState('');
  const [invoiceDate, setInvoiceDate] = useState('');
  const [invoiceNo, setInvoiceNo] = useState('');
  const [invoiceType, setInvoiceType] = useState('1');
  const [totAmount, setTotAmount] = useState('0.00');
  const [collectAmount, setCollectAmount] = useState('0.00');
  const [origin, setOrigin] = useState('');
  const [mawb, setMAWB] = useState('');
  const [totCost, setTotCost] = useState('0.00');

  const [customersList, setCustomersList] = useState([]);
  const [customersListLoaded, setCustomersListLoaded] = useState(false);

  const [miscInvDetails, setMiscInvDetails] = useState([]);

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountNo, setAccountNo] = useState('');
  const [accountName, setAccountName] = useState('');
  const [address, setAddress] = useState('');
  const [nature, setNature] = useState('');
  const [vat, setVAT] = useState('');
  const [tin, setTIN] = useState('');
  const [bs, setBS] = useState('');
  const [p1, setP1] = useState('');
  const [p2, setP2] = useState('');
  const [p3, setP3] = useState('');

  const [miscInvChargesList, setMiscInvChargesList] = useState([]);

  const [printInvoice, setPrintInvoice] = useState(true);

  const invTypeOption = [
    {
      value: '1',
      label: 'Billing Invoice'
    },
    {
      value: '2',
      label: 'Zero-Rated'
    },
    {
      value: '3',
      label: 'Debit Note'
    },
  ];

  const natureOption = [
    {
      value: '',
      label: 'Select...'
    },
    {
      value: 'IF',
      label: 'Inbound Freight'
    },
    {
      value: 'IEX',
      label: 'Inbound Express'
    },
    {
      value: 'OF',
      label: 'Outbound Freight'
    },
    {
      value: 'CCX',
      label: 'Collect'
    },
    {
      value: 'OEX',
      label: 'Outbound Express'
    },
    {
      value: 'DAF',
      label: 'Domestic Airfreight'
    },
    {
      value: 'DAS',
      label: 'Domestic Seafreight'
    },
    {
      value: 'TRK',
      label: 'Domestic Trucking'
    },
  ];

  const getCustomersList = async () => {
    setLoading(true);
    setCustomersList([]);
    setCustomersListLoaded(false);
    await MiscellaneosInvoicingService.getCustomerForMiscInv()
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        setCustomersList(data.responseData);
        setCustomersListLoaded(true);
        setLoading(false);
      })
      .catch((error) => {
        showAlert('error', error.message);
        setLoading(false);
      });
  };

  const getMiscInvDetails = async (miscInvHeaderId) => {
    setLoading(true);

    await MiscellaneosInvoicingService.getMiscInvDetails(miscInvHeaderId)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.responseCode === 200) {
          setMiscInvDetails(data.responseData);

          setHAWB(editData.hawb);
          setFieldValue('hawb', editData.hawb);
          setInvoiceDate(editData.invoiceDate);
          setFieldValue('invoiceDate', editData.invoiceDate);
          setInvoiceNo(editData.invoiceNo);
          setFieldValue('invoiceNo', editData.invoiceNo);
          setInvoiceType(editData.invoiceType);
          setFieldValue('invoiceType', editData.invoiceType);
          setTotAmount(editData.totalAmt);
          setCollectAmount(editData.collectionAmt);
          setOrigin(editData.origin);
          setMAWB(editData.mawb);
          setTotCost(editData.totalCost);

          const _selectedAccount = customersList.find(
            (cus) => cus.accountNo === editData.accountNo
          );
          setSelectedAccount(_selectedAccount || null);
          setAccountNo(editData.accountNo);
          setFieldValue('accountNo', editData.accountNo);
          setAccountName(editData.companyName);
          setAddress(editData.address);
          setNature(editData.nature);
          setVAT(editData.vat);
          setTIN(editData.tin);
          setBS(editData.bs);
          setP1(editData.p1);
          setP2(editData.p2);
          setP3(editData.p3);

          if (data.responseData) {
            const _miscInvDetails = data.responseData;
            let _miscInvChargesList = [];
            _miscInvDetails.forEach((item) => {
              const newMiscInvChargesList = [..._miscInvChargesList];
              newMiscInvChargesList.push({
                id: item.id,
                code: item.code,
                vat: item.vat,
                charges: item.charges,
                desc: item.desc,
                amount: item.amount
              });
              _miscInvChargesList = newMiscInvChargesList;
              setMiscInvChargesList(_miscInvChargesList);
            });
          }
        } else {
          setMiscInvDetails([]);
          showAlert('error', data.responseMessage);
        }
      })
      .catch((error) => {
        showAlert('error', error.message);
      });

    setLoading(false);
  };

  useEffect(async () => {
    await getCustomersList();
  }, []);

  useEffect(async () => {
    if (customersListLoaded && editData) {
      await getMiscInvDetails(editData.id);
    }
  }, [customersListLoaded]);

  const showAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const hideAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const miscInvSchema = Yup.object({
    hawb: Yup.string().required('This field is requried'),
    invoiceDate: Yup.string().required('This field is requried'),
    invoiceNo: Yup.string().required('This field is requried'),
    invoiceType: Yup.string().required('This field is requried'),
    accountNo: Yup.string().required('This field is requried')
  });

  const formik = useFormik({
    initialValues: {
      hawb: '',
      invoiceDate: '',
      invoiceNo: '',
      invoiceType: '1',
      accountNo: ''
    },
    validationSchema: miscInvSchema,
    onSubmit: () => {
      handleOpenConfirm();
    }
  });

  const { errors, touched, handleSubmit, setFieldValue, getFieldProps, setErrors } = formik;

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleConfirmSubmit = () => {
    setOpenConfirm(false);

    const dataHeader = {
      hawb,
      invoiceDate: invoiceDate !== '' ? moment(invoiceDate).format('YYYY-MM-DD') : '',
      invoiceNo,
      invoiceType,
      totAmount,
      collectAmount,
      origin,
      mawb,
      totCost,
      accountNo,
      nature,
      bs,
      p1,
      p2,
      p3
    };

    let dataDetails = [];

    miscInvChargesList.forEach((item, index) => {
      const dataDetail = {
        miscInvHeaderId: 0,
        code: item.code,
        vat: item.vat,
        charges: item.charges,
        desc: item.desc,
        amount: item.amount
      };
      dataDetails.push(dataDetail);
    });

    onSubmit(dataHeader, dataDetails, printInvoice);
  };

  const onAddMiscInvChanges = (code, vat, charges, desc, amount) => {
    const newMiscInvChargesList = [...miscInvChargesList];
    newMiscInvChargesList.push({
      code: code,
      vat: vat,
      charges: charges,
      desc: desc,
      amount: amount
    });
    setMiscInvChargesList(newMiscInvChargesList);
    // setMiscFeesUpdated(invoiceMode == 'SEARCH');
  };

  const onEditMiscInvChanges = (code, newCode, newVat, newCharges, newDesc, newAmount) => {
    const newMiscInvChargesList = miscInvChargesList.map((miscInvCharge) => {
      if (miscInvCharge.code === code) {
        return {
          ...miscInvCharge,
          code: newCode,
          vat: newVat,
          charges: newCharges,
          desc: newDesc,
          amount: newAmount
        };
      }
      return miscInvCharge;
    });

    setMiscInvChargesList(newMiscInvChargesList || []);
    // setMiscFeesUpdated(invoiceMode == 'SEARCH');
  };

  const onDeleteMiscInvChanges = (code) => {
    const newMiscInvChargesList = miscInvChargesList
      .filter((miscInvCharge) => miscInvCharge.code !== code)
      .map((miscInvCharge) => {
        return miscInvCharge;
      });
    setMiscInvChargesList(newMiscInvChargesList || []);
    // setMiscFeesUpdated(invoiceMode == 'SEARCH');
  };

  useEffect(() => {
    const totalMiscInvCharges = (miscInvCharges) => {
      return miscInvCharges.map(({ amount }) => parseFloat(amount)).reduce((sum, i) => sum + i, 0);
    };
    const totalMiscInvChrgs = totalMiscInvCharges(miscInvChargesList);

    const totalVMiscInvCharges = (miscInvCharges) => {
      return miscInvCharges
        .filter((miscInvCharge) => miscInvCharge.vat === 'V')
        .map(({ amount }) => parseFloat(amount))
        .reduce((sum, i) => sum + i, 0);
    };
    let vatableChrgs = totalVMiscInvCharges(miscInvChargesList);
    vatableChrgs = vatableChrgs * (12 / 100);
    const totAmt = totalMiscInvChrgs + vatableChrgs;

    setTotAmount(parseFloat(totAmt).toFixed(2));
  }, [miscInvChargesList]);

  const handleClear = () => {
    setHAWB('');
    setInvoiceDate('');
    setInvoiceNo('');
    setInvoiceType('1');
    setTotAmount('0.00');
    setCollectAmount('0.00');
    setOrigin('');
    setMAWB('');
    setTotCost('0.00');
    setSelectedAccount(null);
    setAccountNo('');
    setAccountName('');
    setAddress('');
    setNature('');
    setVAT('');
    setTIN('');
    setBS('');
    setP1('');
    setP2('');
    setP3('');
    setMiscInvChargesList([]);
    setErrors({});
  };

  return (
    <>
      <ThreeCircles
        height="100"
        width="100"
        color="#ed1c24"
        wrapperStyle={{
          position: 'fixed',
          justifyContent: 'center',
          alignItems: 'center',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          zIndex: '99999',
          textAlign: 'center'
        }}
        wrapperClass=""
        visible={loading}
        ariaLabel="three-circles-rotating"
        outerCircleColor=""
        innerCircleColor=""
        middleCircleColor=""
      />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate style={{ padding: '30px' }}>
          <Divider sx={{ mb: '30px' }}>
            <Chip label="Invoice Details" size="medium" />
          </Divider>

          <Stack direction="row" spacing={3} sx={{ mb: 4 }}>
            <Stack spacing={2} sx={{ width: 1 }}>
              <TextField
                id="hawb"
                name="hawb"
                fullWidth
                label="HAWB No."
                type="text"
                value={hawb}
                onChange={(e) => {
                  setHAWB(e.target.value ? e.target.value : '');
                  setFieldValue('hawb', e.target.value ? e.target.value : '');
                }}
                error={Boolean(touched.hawb && errors.hawb)}
                helperText={touched.hawb && errors.hawb}
              />

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Invoice Date"
                  value={invoiceDate}
                  onChange={(value) => {
                    setInvoiceDate(value);
                    setFieldValue('invoiceDate', value);
                  }}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      fullWidth
                      id="invoiceDate"
                      name="invoiceDate"
                      {...getFieldProps('invoiceDate')}
                      error={Boolean(touched.invoiceDate && errors.invoiceDate)}
                      helperText={touched.invoiceDate && errors.invoiceDate}
                    />
                  )}
                />
              </LocalizationProvider>

              <TextField
                id="invoiceNo"
                name="invoiceNo"
                fullWidth
                label="Invoice No."
                type="text"
                value={invoiceNo}
                onChange={(e) => {
                  setInvoiceNo(e.target.value ? e.target.value : '');
                  setFieldValue('invoiceNo', e.target.value ? e.target.value : '');
                }}
                error={Boolean(touched.invoiceNo && errors.invoiceNo)}
                helperText={touched.invoiceNo && errors.invoiceNo}
              />

              <TextField
                fullWidth
                id="invoiceType"
                name="invoiceType"
                select
                label="Invoice Type"
                defaultValue=""
                value={invoiceType}
                onChange={(e) => {
                  setInvoiceType(e.target.value);
                  setFieldValue('invoiceType', e.target.value ? e.target.value : '');
                }}
                error={Boolean(touched.invoiceType && errors.invoiceType)}
                helperText={touched.invoiceType && errors.invoiceType}
              >
                {invTypeOption &&
                  invTypeOption.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </TextField>

              <TextField
                id="totAmount"
                name="totAmount"
                fullWidth
                label="Total Amount"
                type="number"
                value={totAmount}
                disabled
                onChange={(e) => {
                  setTotAmount(e.target.value ? e.target.value : '');
                  setFieldValue('totAmount', e.target.value ? e.target.value : '');
                }}
                error={Boolean(touched.totAmount && errors.totAmount)}
                helperText={touched.totAmount && errors.totAmount}
              />
            </Stack>

            <Stack spacing={2} sx={{ width: 1 }}>
              <TextField
                id="collectAmount"
                name="collectAmount"
                fullWidth
                label="Collection Amount"
                type="number"
                value={collectAmount}
                onChange={(e) => {
                  setCollectAmount(e.target.value ? e.target.value : '');
                  setFieldValue('collectAmount', e.target.value ? e.target.value : '');
                }}
                error={Boolean(touched.collectAmount && errors.collectAmount)}
                helperText={touched.collectAmount && errors.collectAmount}
              />

              <TextField
                id="origin"
                name="origin"
                fullWidth
                label="Origin"
                type="text"
                value={origin}
                onChange={(e) => {
                  setOrigin(e.target.value ? e.target.value : '');
                  setFieldValue('origin', e.target.value ? e.target.value : '');
                }}
                error={Boolean(touched.origin && errors.origin)}
                helperText={touched.origin && errors.origin}
              />

              <TextField
                id="mawb"
                name="mawb"
                fullWidth
                label="MAWB No."
                type="text"
                value={mawb}
                onChange={(e) => {
                  setMAWB(e.target.value ? e.target.value : '');
                  setFieldValue('mawb', e.target.value ? e.target.value : '');
                }}
                error={Boolean(touched.mawb && errors.mawb)}
                helperText={touched.mawb && errors.mawb}
              />

              <TextField
                id="totCost"
                name="totCost"
                fullWidth
                label="Total Cost"
                type="number"
                value={totCost}
                onChange={(e) => {
                  setTotCost(e.target.value ? e.target.value : '');
                  setFieldValue('totCost', e.target.value ? e.target.value : '');
                }}
                error={Boolean(touched.totCost && errors.totCost)}
                helperText={touched.totCost && errors.totCost}
              />
            </Stack>
          </Stack>

          <Divider sx={{ mb: '30px' }}>
            <Chip label="Customer Details" size="medium" />
          </Divider>

          <Stack direction="row" spacing={3} sx={{ mb: 4 }}>
            <Stack spacing={2} sx={{ width: 1 }}>
              <Autocomplete
                fullWidth
                disabled={!customersListLoaded}
                options={customersList}
                noOptionsText="No record found"
                getOptionLabel={(option) => option.displayName}
                value={selectedAccount}
                onChange={(e, value) => {
                  if (value) {
                    setSelectedAccount(value);
                    setAccountNo(value.accountNo);
                    setFieldValue('accountNo', value.accountNo);

                    setAccountName(value.companyName);
                    setAddress(value.address);
                    setVAT(value.vat);
                    setTIN(value.tin);
                  } else {
                    setAccountNo('');
                    setFieldValue('accountNo', '');

                    setAccountName('');
                    setAddress('');
                    setVAT('');
                    setTIN('');
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Account No."
                    name="accountNo"
                    //value={accountNo}
                    {...getFieldProps('accountNo')}
                    error={Boolean(touched.accountNo && errors.accountNo)}
                    helperText={touched.accountNo && errors.accountNo}
                  />
                )}
              />

              <TextField
                id="accountName"
                name="accountName"
                fullWidth
                label="Account Name"
                type="text"
                value={accountName}
                disabled
              />

              <TextField
                id="address"
                name="address"
                fullWidth
                label="Address"
                type="text"
                value={address}
                disabled
              />

              <TextField
                fullWidth
                id="nature"
                name="nature"
                select
                label="Nature"
                defaultValue=""
                value={nature}
                onChange={(e) => {
                  setNature(e.target.value);
                  setFieldValue('nature', e.target.value ? e.target.value : '');
                }}
                error={Boolean(touched.nature && errors.nature)}
                helperText={touched.nature && errors.nature}
              >
                {natureOption &&
                  natureOption.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>

            <Stack spacing={2} sx={{ width: 1 }}>
              <TextField
                id="vat"
                name="vat"
                fullWidth
                label="VAT"
                type="text"
                value={vat}
                disabled
              />

              <TextField
                id="tin"
                name="tin"
                fullWidth
                label="TIN"
                type="text"
                value={tin}
                disabled
              />

              <TextField
                id="bs"
                name="bs"
                fullWidth
                label="BS"
                type="text"
                value={bs}
                onChange={(e) => {
                  setBS(e.target.value ? e.target.value : '');
                  setFieldValue('bs', e.target.value ? e.target.value : '');
                }}
                error={Boolean(touched.bs && errors.bs)}
                helperText={touched.bs && errors.bs}
              />
            </Stack>
          </Stack>

          <Stack spacing={2} sx={{ mb: 4 }}>
            <TextField
              id="p1"
              name="p1"
              fullWidth
              label="P1"
              type="text"
              value={p1}
              onChange={(e) => {
                setP1(e.target.value ? e.target.value : '');
                setFieldValue('p1', e.target.value ? e.target.value : '');
              }}
              error={Boolean(touched.p1 && errors.p1)}
              helperText={touched.p1 && errors.p1}
            />

            <TextField
              id="p2"
              name="p2"
              fullWidth
              label="P2"
              type="text"
              value={p2}
              onChange={(e) => {
                setP2(e.target.value ? e.target.value : '');
                setFieldValue('p2', e.target.value ? e.target.value : '');
              }}
              error={Boolean(touched.p2 && errors.p2)}
              helperText={touched.p2 && errors.p2}
            />

            <TextField
              id="p3"
              name="p3"
              fullWidth
              label="P3"
              type="text"
              value={p3}
              onChange={(e) => {
                setP3(e.target.value ? e.target.value : '');
                setFieldValue('p1', e.target.value ? e.target.value : '');
              }}
              error={Boolean(touched.p3 && errors.p3)}
              helperText={touched.p3 && errors.p3}
            />
          </Stack>

          <Divider sx={{ mb: '30px' }}>
            <Chip label="Invoice Charges" size="medium" />
          </Divider>

          <Stack spacing={1} sx={{ mb: 4 }}>
            <MiscInvCharges
              miscInvChargesList={miscInvChargesList}
              onAddMiscInvChanges={onAddMiscInvChanges}
              onEditMiscInvChanges={onEditMiscInvChanges}
              onDeleteMiscInvChanges={onDeleteMiscInvChanges}
            />
          </Stack>

          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
            sx={{ width: 1, marginTop: 5 }}
          >
            <Button
              size="large"
              type="button"
              variant="outlined"
              loading={isSubmitting || false}
              sx={{ width: { xs: '100%', sm: '100%', md: '200px' } }}
              onClick={handleClear}
            >
              Clear
            </Button>
            <LoadingButton
              size="large"
              type="button"
              variant="contained"
              loading={isSubmitting || false}
              sx={{ width: { xs: '100%', sm: '100%', md: '200px' }, alignSelf: 'center' }}
              onClick={handleSubmit}
            >
              Save
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>

      <Dialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move', color: '#ed1c24' }} id="draggable-dialog-title">
          {editData ? 'Edit Misc. Invoice Confirmation' : 'Add New Misc. Invoice Confirmation'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to save new misc. invoice?</DialogContentText>
          <div style={{ textAlign: 'left' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={printInvoice}
                  onChange={(e) => setPrintInvoice(e.target.checked)}
                  name="printInvoice"
                />
              }
              label="Print invoice after saving"
              value="end"
              labelPlacement="end"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseConfirm}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleConfirmSubmit} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
